require("core-js/modules/es.number.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.time = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getYMD = getYMD;
  _exports.turnTime = turnTime;
  _exports.formateTime = formateTime;

  function getYMD(time, isHideHours) {
    if (!time) {
      return '-';
    }

    if ("".concat(time).length == 10) {
      time = time * 1000;
    }

    var t = new Date(time);
    time = t.getFullYear();
    time += "/".concat(t.getMonth() + 1 > 9 ? t.getMonth() + 1 : '0' + (t.getMonth() + 1));
    time += "/".concat(t.getDate() > 9 ? t.getDate() : '0' + t.getDate());

    if (!isHideHours) {
      time += "  ".concat(t.getHours() > 9 ? t.getHours() : '0' + t.getHours());
      time += ":".concat(t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes());
      time += ":".concat(t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds());
    }

    return time;
  }
  /*
   *国际标准时间转换为普通时间格式
   */


  function turnTime(time, isHideHours) {
    if (!time) {
      return '-';
    }

    var t = new Date(time);
    time = t.getFullYear();
    time += "/".concat(t.getMonth() + 1 > 9 ? t.getMonth() + 1 : '0' + (t.getMonth() + 1));
    time += "/".concat(t.getDate() > 9 ? t.getDate() : '0' + t.getDate());

    if (!isHideHours) {
      time += "  ".concat(t.getHours() > 9 ? t.getHours() : '0' + t.getHours());
      time += ":".concat(t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes());
      time += ":".concat(t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds());
    }

    return time;
  }
  /*
   *国际标准时间转换为普通时间格式
   */


  function formateTime(time) {
    var formate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'y-m-d h:f:s';

    if (!time) {
      return '-';
    }

    var res = formate;
    "".concat(time).length == 10 && (time = Number(time) * 1000);
    var t = new Date(time);
    var year = t.getFullYear();
    var month = t.getMonth() + 1 > 9 ? t.getMonth() + 1 : '0' + (t.getMonth() + 1);
    var day = t.getDate() > 9 ? t.getDate() : '0' + t.getDate();
    var hours = t.getHours() > 9 ? t.getHours() : '0' + t.getHours();
    var minute = t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes();
    var sceond = t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds();
    res = res.replace('y', year);
    res = res.replace('m', month);
    res = res.replace('d', day);
    res = res.replace('h', hours);
    res = res.replace('f', minute);
    res = res.replace('s', sceond);
    return res;
  }
});