require("core-js/modules/es.object.to-string");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.introduceRouter = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var introduce = {
    path: '/introduce',
    redirect: '/introduce/oxySnap',
    name: 'Introduce',
    component: function component() {
      return import('@/Layout');
    },
    meta: {
      title: '使用教程'
    },
    children: [{
      path: 'oxySnap',
      component: function component() {
        return import('@/pages/introduce/oxySnap');
      },
      name: 'OxySnap',
      meta: {
        title: '随手测使用教程'
      }
    }]
  };
  var _default = introduce;
  _exports.default = _default;
});